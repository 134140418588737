import { useEffect, useRef, useState } from 'react'
import { chatPromptApi } from '../../../services/chat'
import ChatInput from '../../molecules/ChatInput/ChatInput'
import ChatMessage, { ChatMessageProps, USER_TYPE } from '../../molecules/ChatMessage/ChatMessage'

export interface ChatProps {}

const Chat = (inputProps: ChatProps) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [chatHistory, setChatHistory] = useState<ChatMessageProps[]>([
		{
			id: '0',
			user: USER_TYPE.ASSISTANT,
			dateTime: new Date(),
			message: `👋 Welcome to Debenhams, your ultimate one-stop digital destination for Fashion, Beauty, Homeware, and Garden collections.

We're here to assist you - what can we help you find today?`,
		},
	])

	async function sendQuestion(chatHistory: ChatMessageProps[], responseId: string): Promise<ChatMessageProps> {
		return chatPromptApi(chatHistory)
	}

	const handleNewQuestion = async (question: string) => {
		setIsLoading(true)
		let newMessages = [
			{
				id: (chatHistory.length + 1).toString(),
				user: USER_TYPE.USER,
				dateTime: new Date(),
				message: question,
			},
			...chatHistory,
		]
		setChatHistory(newMessages)
		await delay(1500)
		newMessages = [
			{
				id: (chatHistory.length + 2).toString(),
				user: USER_TYPE.ASSISTANT,
				dateTime: new Date(),
			},
			{
				id: (chatHistory.length + 1).toString(),
				user: USER_TYPE.USER,
				dateTime: new Date(),
				message: question,
			},
			...chatHistory,
		]
		setChatHistory(newMessages)
		sendQuestion(newMessages, newMessages[0].id).then((respMessage) => {
			newMessages = [
				{
					...respMessage,
					id: (chatHistory.length + 2).toString(),
					dateTime: new Date(respMessage.dateTime),
				},
				{
					id: (chatHistory.length + 1).toString(),
					user: USER_TYPE.USER,
					dateTime: new Date(),
					message: question,
				},
				...chatHistory,
			]
			setChatHistory(newMessages)
			setIsLoading(false)
		})
	}
	const bottomRef = useRef<HTMLDivElement>(null)

	return (
		<div className='flex flex-col justify-between w-full'>
			<div className='flex flex-col-reverse flex-grow overflow-y-auto p-3 gap-2 scroll-smooth w-full'>
				{chatHistory.map((m) => {
					return <ChatMessage key={m.id} {...m} />
				})}
			</div>
			<div className='sticky bottom-0 bg-white p-3'>
				<ChatInput handleNewQuestion={handleNewQuestion} isLoading={isLoading} />
			</div>
		</div>
	)
}

function delay(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}
export default Chat
