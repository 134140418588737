import { useEffect, useState } from "react";

const ChatTyping = () => {
  const [numPeriods, setNumPeriods] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setNumPeriods((numPeriods) => (numPeriods + 1) % 6);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return <div className="font-semibold text-xl">.{".".repeat(numPeriods)}</div>;
};

export default ChatTyping;
