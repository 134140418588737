export interface ProductCardProps {
	id: string
	url: string
	title: string
	subTitle?: string
	body: string
	image: string
	load?: boolean
}

const ProductCard = (inputProps: ProductCardProps) => {
	if (!inputProps.load) {
		return <div></div>
	}

	const handleButtonClick = () => {
		window.open(inputProps.url, '_blank')
	}

	return (
		<div className={`bg-white shadow-lg p-2 h-full rounded-lg `}>
			<button className='flex flex-col gap-3 h-full' onClick={handleButtonClick}>
				<div className='flex justify-center items-center w-full'>
					<div className='flex flex-row items-center border-black'>
						<img className='object-cover' src={`${inputProps.image}`} />
					</div>
				</div>
				<div className='flex flex-col w-full h-full justify-between gap-1'>
					<div className='flex flex-col'>
						<div className='flex flex-row w-full'>
							<h1 className='flex-auto capitalize text-left text-slate-900 font-semibold text-md lg:text-xl'>{inputProps.title}</h1>
						</div>
						<p className='flex-auto text-left text-slate-900 text-sm lg:text-lg'>{inputProps.body}</p>
					</div>
					<div className='flex'>
						<h1 className='text-slate-500 text-left text-sm lg:text-lg'>{inputProps.subTitle}</h1>
					</div>
				</div>
			</button>
		</div>
	)
}

export default ProductCard
