import React, { useEffect, useRef, useState } from 'react'

export interface ChatInputProps {
	handleNewQuestion: any
	isLoading: boolean
}

const ChatInput = (inputProps: ChatInputProps) => {
	const [inputText, setInputText] = useState<string>('')

	const handleSubmit = async (e: any) => {
		e.preventDefault()
		setInputText('')
		inputProps.handleNewQuestion(inputText, e)
	}

	const handleKeyDown = async (e: any) => {
		if (e.key === 'Enter') {
			handleSubmit(e)
		}
	}

	const handleOnChange = async (e: any) => {
		setInputText(e.target.value)
	}

	return (
		<div className='w-full flex pb-5 h-[90px]'>
			<form onSubmit={handleSubmit} className='flex w-full rounded-sm'>
				<textarea
					className='w-full p-5 border-2 rounded-lg focus:outline-none rounded-r-none input whitespace-nowrap'
					placeholder='ask a question'
					autoFocus
					name='postContent'
					onChange={handleOnChange}
					value={inputText}
					disabled={inputProps.isLoading == true}
					onKeyDown={handleKeyDown}
				/>
				<button
					className='px-6 font-semibold rounded-lg bg-blue-800 hover:bg-blue-600 text-white rounded-l-none'
					type='submit'
					disabled={inputText.trim().length ? false : true}
					onClick={handleSubmit}
				>
					Send
				</button>
			</form>
		</div>
	)
}

export default ChatInput
