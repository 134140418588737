import React, { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ChatTyping from '../ChatTyping/ChatTyping'

interface TypingAnimationProps {
	text?: string
	onComplete: () => void
	speed?: number
}

const TypingAnimation = (inputProps: TypingAnimationProps) => {
	const [typedText, setTypedText] = useState('')
	const [isTyping, setIsTyping] = useState(true)
	const intervalRef = useRef<number | null>(null)

	useEffect(() => {
		if (isTyping === false) {
			inputProps.onComplete()
		}
	}, [isTyping])

	useEffect(() => {
		if (inputProps.text) {
			setIsTyping(true)
			setTypedText('')

			let i = 0
			const textLength = inputProps.text.length
			console.log(inputProps.text)
			intervalRef.current = window.setInterval(() => {
				setTypedText((prevTypedText) => {
					const nextTypedText = inputProps!.text!.slice(0, i)
					i++

					if (i > textLength) {
						clearInterval(intervalRef.current!)
						setIsTyping(false)
					}

					return nextTypedText
				})
			}, inputProps.speed || 20)

			return () => {
				if (intervalRef.current) {
					clearInterval(intervalRef.current)
				}
			}
		}
	}, [inputProps.text])

	if (!inputProps.text) {
		return <ChatTyping />
	}

	return <ReactMarkdown className='flex-col prose text-lg flex-wrap' children={typedText} />
}

export default TypingAnimation
