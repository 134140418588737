import './App.css'
import Chat from './components/organisms/chat/chat'

function App() {
	return (
		<div className='flex h-[calc(100dvh)]'>
			<div className='flex w-0 lg:w-1/4' />
			<div className='flex justify-center w-screen lg:w-1/2 p-2'>
				<Chat />
			</div>
			<div className='flex w-0 lg:w-1/4' />
		</div>
	)
}

export default App
