import axios from 'axios'
import { ChatMessageProps } from '../../components/molecules/ChatMessage/ChatMessage'

const env = process.env.REACT_APP_ENV || 'play'
const lambdaUrl = `https://chat.${env}.dbztech.net/api`

export async function chatPromptApi(chatHistory: ChatMessageProps[]): Promise<ChatMessageProps> {
	const resp = await axios.post(lambdaUrl, chatHistory)
	const respProps = resp.data as ChatMessageProps
	console.log(JSON.stringify(respProps))
	return respProps
}
