import { useEffect, useState } from 'react'
import ChatTyping from '../../atoms/ChatTyping/ChatTyping'
import TypingAnimation from '../../atoms/TypingAnimation/TypingAnimation'
import Carousel from '../Carousel/Carousel'
import { ProductCardProps } from '../ProductCard/ProductCard'

export enum USER_TYPE {
	ASSISTANT = 'assistant',
	USER = 'user',
}

export interface ChatMessageProps {
	id: string
	user: USER_TYPE
	dateTime: Date
	message?: string
	attachments?: ProductCardProps[]
	searchParams?: string
}

const botStyle = {
	bubble: 'bg-teal-200',
	text: 'justify-start text-black font-light',
}
const userStyle = {
	bubble: 'bg-blue-800',
	text: 'justify-end font-semibold',
}

const ChatMessage = (inputProps: ChatMessageProps) => {
	const [isLoaded, setIsLoaded] = useState(false)
	const [textLoaded, setTextLoaded] = useState(false)
	const [debug, setDebug] = useState(false)

	useEffect(() => {
		setIsLoaded(true)

		const queryParams = new URLSearchParams(window.location.search)
		const hasQueryParamDebug = queryParams.has('debug')
	}, [])

	const handleTextLoaded = async () => {
		setTextLoaded(true)
	}

	return (
		<div
			className={`text-white transition font-semibold	delay-300 flex flex-col w-full h-auto shadow-lg rounded-xl rounded-b-sm p-4 text-lg gap-4
				${isLoaded ? 'opacity-100 transition duration-200' : 'opacity-0 translate-y-4'}
				${inputProps.user == USER_TYPE.ASSISTANT ? botStyle.bubble : userStyle.bubble}
      `}
		>
			<article className={`flex subpixel-antialiased ${inputProps.user == USER_TYPE.ASSISTANT ? botStyle.text : userStyle.text}`}>
				{inputProps.user == USER_TYPE.ASSISTANT ? <TypingAnimation key={inputProps.id} text={inputProps.message} onComplete={handleTextLoaded} /> : inputProps.message}
			</article>
			{textLoaded && inputProps?.attachments && (
				<div className='flex py-1 flex-col gap-3'>
					<Carousel items={inputProps?.attachments} />
					{/* <div className='italic text-red-400 text-sm'>{debug ? `* Algolia Debug : ${inputProps.searchParams}` : ''}</div> */}
				</div>
			)}
			<div
				className={`flex text-xs font-light
          ${inputProps.user == USER_TYPE.ASSISTANT ? botStyle.text : userStyle.text}
          ${inputProps.message ? 'opacity-100 transition duration-1000' : 'opacity-0 translate-y-4'}
        `}
			>
				{inputProps.dateTime.toLocaleTimeString().split(':')[0]}:{inputProps.dateTime.toLocaleTimeString().split(':')[1]}
			</div>
		</div>
	)
}

export default ChatMessage
