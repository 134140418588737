import { Keyboard, Mousewheel, Navigation, Pagination, Zoom } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper-bundle.css'
import ProductCard, { ProductCardProps } from '../ProductCard/ProductCard'

export interface CarouselProps {
	items: ProductCardProps[]
}

const Carousel = (inputProps: CarouselProps) => {
	return (
		<div className='flex flex-row'>
			<Swiper slidesPerView={inputProps.items.length > 1 ? 2 : 1} navigation={true} keyboard={true} spaceBetween={5} modules={[Navigation, Pagination, Keyboard]}>
				{inputProps.items.map((i) => {
					return (
						<SwiperSlide>
							<ProductCard id={i.id} url={i.url} title={i.title} subTitle={i.subTitle} body={i.body} image={i.image} load={true} />
						</SwiperSlide>
					)
				})}
			</Swiper>
		</div>
	)
}

export default Carousel
